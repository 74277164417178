@charset "utf-8";
@import "reset";
$breakpoints: (
    'sp':'screen and (max-width: 1024px)',
    'pc':'screen and (min-width: 1025px)'
) !default;
@mixin bp($breakpoint: sp) {
    @media #{map-get($breakpoints,$breakpoint)} {
        @content;
    }
}
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:700');
$black: #000;
$base1: #ea9875;
$wf:'Noto Sans JP', sans-serif;

@mixin fnt-set{
font-feature-settings: "palt";
text-align: justify;
text-justify: inter-ideograph; 
}

@mixin fnt-arial {
font-family: Arial,sans-serif; 
}

@mixin font {
  font-family: "游ゴシック体", YuGothic,"Yu Gothic","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,"ＭＳ Ｐゴシック","MS PGothic","sans-serif";
  color: $black;
  font-weight: 500;
  font-size: 18px * 0.8;
line-height: 1.7;

	@include bp{
	font-size: 15px;
	}

}
@mixin fl {
    display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
}
@mixin fl_x {
    display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
}
@mixin fl_y {
    display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
}
@mixin fl_w {
    display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
@mixin po($position: absolute,$top: 0,$left: 0,$right: 0,$bottom: 0) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
  margin: auto;
}
@mixin form-reset{
	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
	margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}
@mixin rsp-img{
	width: 100%;
	height: auto;
}


// * hack IE
$hack-ie11: "*::-ms-backdrop";
@mixin hack-ie($hack-ver:'') {
    // * IE8 hack
    @if $hack-ver == "ie8" {
        @media \0screen {
            @content;
        }
    }
    // * IE9-10 hack(add propary \9)
    @else if $hack-ver == "ie9-10" {
        @media all and (min-width:0\0) {
            @content;
        }
    }
    // * IE10 hack(add propary \9)
    @else if $hack-ver == "ie10" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
    // * IE11 hack(add selector *::-ms-backdrop)
    @else if $hack-ver == "ie11" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
}
/*basic*/
.clearfix:after{content:".";display:block;clear:both;height:0;visibility:hidden;}.clearfix{min-height:1px;}.clear{clear:both;}
@include bp {
    .pc {display:none !important;}
}
@include bp(pc) {
    .sp {display:none !important;}
}
body,input,textarea,select {
    @include font;

		@media (max-width: 480px) {
		font-size: 14px;
		}

}

h1,h2,h3,h4,h5,h6{
	font-weight:500;
	font-feature-settings: "palt";
}

main {
  overflow: hidden;
	position: relative;
}
a {
	color: $black;
}

@include bp(pc) {
  a {
		-webkit-transition: 0.3s;
		transition: 0.3s;
		opacity: 1;

		&:hover{
		opacity: .6;
		}

	}
	a img {
		-webkit-transition: 0.3s;

		&:hover{
		opacity: .6;
		}

	}
}
.delay0_1{
animation-delay: 0.1s;
-webkit-animation-delay: 0.1s;
}
.delay0_2{
animation-delay: 0.2s;
-webkit-animation-delay: 0.2s;
}
.delay0_5{
animation-delay: 0.5s;
-webkit-animation-delay: 0.5s;
}
.delay0_7{
animation-delay: 0.7s;
-webkit-animation-delay: 0.7s;
}
.delay0_9{
animation-delay: 0.9s;
-webkit-animation-delay: 0.9s;
}
.delay1{
animation-delay: 1s;
-webkit-animation-delay: 1s;
}
.delay1_1{
animation-delay: 1.1s;
-webkit-animation-delay: 1.1s;
}
.delay1_2{
animation-delay: 1.2s;
-webkit-animation-delay: 1.2s;
}
.delay1_3{
animation-delay: 1.3s;
-webkit-animation-delay: 1.3s;
}
.delay1_4{
animation-delay: 1.4s;
-webkit-animation-delay: 1.4s;
}
.delay1_6{
animation-delay: 1.6s;
-webkit-animation-delay: 1.6s;
}
.delay1_8{
animation-delay: 1.8s;
-webkit-animation-delay: 1.8s;
}
.fadeIn-right{
  -webkit-animation-name: fadeIn-right;
  animation-name: fadeIn-right;

}
@-webkit-keyframes fadeIn-right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn-right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.anm-fdin_r,.anm-fdin,.anm-fdup{
opacity: 0;
}
header{
border-top:10px solid #ff0000;
position: absolute;
left: 0;
top: 0;
width: 100%;
z-index: 100;
background:rgba(255,255,255,0);
transition: 1s;

	@media (max-width: 1200px) {
	border-top:5px solid #ff0000;
	}

	&.fixed{
	position: fixed;
	background:rgba(255,255,255,1);
	}

		.inner-wrapper{
		max-width: 1080px;
		margin: auto;
		display: flex;
		align-items: center;
		height: 78px;

			@media (max-width: 1200px) {
			padding: 0 50px;
			height: 60px;
			}

			@media (max-width: 1200px) {
			padding: 0 20px;
			}

			.site-logo{
			padding-top: 10px;

				@media (max-width: 1200px) {
				padding-top: 0;
				}

				@include bp{
				width: 100px;

					img{
					width: 100%;
					height: auto;
					}

				}

				@media (max-width: 767px) {
				padding-top: 5px;
				}


			}

			.num1{
			margin-left: 40px;
			padding-top: 10px;

				@media (max-width: 1200px) {
				padding-top:0;
				margin-left: 20px;
				}

				@media (max-width: 767px) {
				display: none;
				}

			}

			.num2{
			margin-left: 77px;
			padding-top: 10px;

				@media (max-width: 1200px) {
				padding-top:0;
				margin-left: 30px;
				}

				@media (max-width: 767px) {
				display: none;
				}

			}


		}

}/* header end */

footer{
border-top:2px solid #c8c8c8;
padding-top: 75px; 

	@media (max-width: 1440px) {
	padding-top: 50px;
	}

	.inner{
	max-width: 1430px;
	margin: auto;
	display: flex;

		@media (max-width: 1440px) {
		width: 100%;
		}

		@include bp{
		display: block;
		}

		.num{
		width: (100% / 3);
		box-sizing: border-box;

			@media (max-width: 1440px) {
			width: 50%;
			padding: 0 20px;
			margin-bottom: 20px;
			}

			@include bp{
			width: 100%;
			margin-bottom: 40px;
			}

			h3{
			margin-bottom:30px;
			font-size: 19px * 0.8;
			font-weight: bold;

				@include bp{
				margin-bottom:20px;
				font-size: 15px;
				text-align: center;
				}

			}

		}

		.num1{
		padding-right: 20px;

			.insta-list{
			display: flex;
			flex-wrap: wrap;

				@include bp{
				max-width: 500px;
				margin: auto;
				}

			
				li{
				width: 49%;
				margin-bottom: 2%;
					
					img{
					width: 100%;
					height: auto;
					vertical-align: top;
					}

					&:nth-child(even){
					margin-left: 2%;
					}

					&:last-child,&:nth-last-child(2){
					margin-bottom:0;
					}



				}

			}/* .insta-list end */
		
		}/* .num1 end */

		.num2{
		padding: 0 20px;
		border-left:2px solid #c8c8c8; 
		border-right:2px solid #c8c8c8; 

			.fb-area{
			text-align: center;
			}

		}/* .num2 end */

		.num3{
		position: relative;
		padding-left: 20px;

			.f-nav{
			
				a{
				display: block;
				color: black;
				font-size: 19px * 0.8;
				font-weight:  bold;
				margin-bottom: 10px;
				}

			}/* .f-nav end */

			.bottom-area{
			position: absolute;
			bottom: 0;
			left: 20px;

				@media (max-width: 1440px) {
				position: inherit;
				bottom: inherit;
				left: inherit;
				margin-top: 30px;
				}


				.add{
				display: flex;
				font-size: 17px * 0.8;

				@media (max-width: 767px) {
				display: block;
				}

					.cate{
					width:160px;

						@media (max-width: 1440px) {
						width: 120px;
						}

						@media (max-width: 767px) {
						width: 100%;
						margin-bottom: 5px;
						font-weight: bold;
						}

					}

					.txt{

						@media (max-width: 767px) {
						margin-bottom: 15px;
						}

					}

				}

			}// .bottom-area end

		}/* .num3 end */

	}/* .inner end */

	.copyright{
	margin-top: 65px;
	background:#ff0000;
	text-align: center;
	color: white;
	font-size: 15px;
	padding:35px 0;

		@include bp{
		margin-top: 30px;
		padding:15px 0;
		font-size: 12px;
		}

	}

}/* footer end */

.main-slide{
margin-top: 88px;
width: 100%;
position: relative;

	@media (max-width: 1200px) {
	margin-top: 60px;
	}

	.copy{
	position: absolute;
  /* bottom: 210px; */
	bottom: 180px;
  left: 50%;
  transform:translateX(-50%);
  -webkit-transform:translateX(-50%);
	/* 	padding-left: 360px; */
	padding-left: 300px;
	z-index: 10;

		@media (max-width: 1200px) {
		padding-left:0;
		bottom: 100px;		
		}

		@include bp{
		
			img{
			width: 400px;
			height: auto;
			}

		}

		@media (max-width: 480px) {
		bottom: 60px;

			img{
			width: 280px;
			}
		
		}

	}

	.item{
		z-index: 9;
		max-width: 1400px;
	
		figure{
		position: relative;

			a{
			/*width:390px; */
			/* height:60px; */
			width:310px;
			height:50px;
			line-height: 1;
			@include fl;
			background:black;
			border-radius:40px;
			color: white;
			font-family: $wf;
			/* font-size: 28px; */
			font-size: 22px;
			font-weight: bold;
			position: absolute;
		  bottom: 115px;
		  left: 50%;
		  transform:translateX(-50%);
		  -webkit-transform:translateX(-50%);

				@include bp{
				width: 80%;
				height: 40px;
				font-size: 15px;
				bottom: 50px;	
				max-width: 310px;
				}

				@media (max-width: 480px) {
				width: 50%;
				height: 30px;
				font-size: 12px;
				bottom: 30px;	
				}

			}

			&:after{
			content: "";
			background-image:url(../img/slide/arrow.svg);
			/* background-size:87px 24px; */
			background-size:69px 19px;
			-webkit-background-size:69px 19px;
			-moz-background-size:69px 19px;
			width: 69px;
			height: 19px;
			position: absolute;
		  bottom: 60px;
		  left: 50%;
		  transform:translateX(-50%);
		  -webkit-transform:translateX(-50%);

				@include bp{
				background-size:35px auto;
				-webkit-background-size:35px auto;
				-moz-background-size:35px auto;
				width: 35px;
				height: 10px;
				bottom: 20px;
				}

				@media (max-width: 480px) {
				bottom: 10px;
				}

			}
			
		}
		
	}// .item end

}/* .main-slide end */

#menu {
  display: none;
}

.slicknav_menu {
  display: block;
	order: 4;
	margin-left: auto;
}

.sec .com-ttl{
text-align: center;
font-family: $wf;
font-feature-settings: "palt";
font-size: 26px;

	@include bp{
	font-size: 22px;
	line-height: 1.5;
	}

	&:after{
	 content: "";
	 display: block;
	 background: black;
	 width:405px;
	 height:4px ;
	 margin:15px auto 25px;

		@media (max-width: 768px) {
		width:30%;
		max-width: 300px;
		height:3px ;
		margin:15px auto 25px;
		}

	}

}// .sec .com-ttl end

.site-container{
max-width: 1080px;
margin-left: auto;
margin-right: auto;

	@include bp{
	padding: 0 50px;
	}

	@media (max-width: 767px) {
	padding: 0 20px;
	}
	
}/* .site-container end */

.base-btn{
margin: 65px auto;
width:275px;
height:60px;

	@media (max-width: 767px) {
	width: 60%;
	height: 50px;
	margin: 40px auto;
	}

	a{
	background: black;
	@include fl;
	width:275px;
	height:60px;
	color: white;
	font-family: $wf;
	font-size: 22px * 0.8;
	position: relative;
	line-height: 1;

		@media (max-width: 767px) {
		width: 100%;
		max-width: 275px;
		height: 50px;
		margin: 40px auto;
		font-size: 13px;
		}

		&:after{
		position: absolute;
		right:40px;
	  top: 50%;
		content: "";
		width: 28px;
		height: 28px;
		border-top: 1px solid #fff;
		border-right: 1px solid #fff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		margin-top: -14px;
		display: block;
		line-height: 1;

			@media (max-width: 767px) {
			right:20px;
			width: 18px;
			height: 18px;
			margin-top: -9px;
			}

		}

	}

}/* .base-btn end */

.top .sec-top{
max-width: 1080px;
margin:60px auto 115px;

	@media (max-width: 1200px) {
	padding: 0 50px;
	margin:40px auto 80px;
	}

	@media (max-width: 768px) {
	padding: 0 20px;
	margin:40px auto 60px;
	}



 .txt{
 font-size: 18px * 0.8;
 line-height: 1.7;
 text-align: center;

 }

}/* .top .sec-top end */

.top .top-nav-area{
width: 100%;
display: flex;

	@include bp{
	display: block;
	padding: 0 20px;
	}

	li{
	width: (100% / 3);
	border-bottom: 1px solid #c7c8c8;
	border-top: 1px solid #c7c8c8;
	padding: 50px 0 30px;
	box-sizing: border-box;
	transition: .5s;
	background: white;

		@media (max-width: 1200px) {
		padding: 20px;
		}

		@include bp{
		width: 100%;
		border: 1px solid #c7c8c8;
		border-bottom: 0;
		}

		&:hover{
		background:#f3f3f3;
		}

		a{
		display: flex;

			@media (max-width: 1200px) {
			flex-wrap: wrap;
			justify-content: center;
			}

		}

		.thumb{
		padding: 20px 30px;

			@media (max-width: 1200px) {
			padding:0;
			height: 140px;
			display: flex;
			align-items:center;
			}

		}

		.txt-area{
		padding-right: 30px;

			@media (max-width: 1200px) {
			padding:0;
			}
		
			h3{
			font-size:31px * 0.8;
			font-family:$wf;
			line-height: 1;
			margin-bottom: 10px;

				@include bp{
				font-size:20px;
				text-align: center;
				line-height: 1.5;
				}

			}	

			p{
			font-size: 18px * 0.8;
			}
	
		}// .txt-area end

		&:nth-child(2){
		border-left:1px solid #c7c8c8;
		border-right:1px solid #c7c8c8;

			.thumb{
			padding: 0 30px;
			}

		}

		&:last-child{

			@include bp{
			border-bottom: 1px solid #c7c8c8;
			}

		}

	}/* li end */

}/* .top .top-nav-area end */

.top .sec-news{
margin-top: 60px;

@media (max-width: 1200px) {
padding: 0 50px;
}

@media (max-width: 767px) {
padding: 0 20px;
}



	.list{
	max-width: 750px;
	margin: auto;

		li{
		margin-bottom: 20px;
		display: flex;
		opacity: 0;

			@media (max-width: 768px) {
			display: block;
			}

			time{
			font-size: 18px * 0.8;
			display: inline-block;

				@media (max-width: 768px) {
				font-size: 12px;
				}

			}

			.icon{
			color: white;
			border-radius:8px;
			width:122px;
			padding: 4px 10px 1px;
			height: 23px;
			line-height: 1;
			display: inline-block;
			box-sizing: border-box;
			margin: 0 25px 0 70px;
			font-family: $wf;
			text-align: center;

				@media (max-width: 1200px) {
				margin: 0 15px;
				}

				@media (max-width: 767px) {
				font-size: 10px;
				width: 80px;
				height: 19px;
				}			

				&.color1,&.info-schedule{
				background:#0000f8;
				}

				&.color2,&.info-group-purchase{
				background:#76b3fd;
				}

				&.color3,&.info-seminar{
				background:#0071fc;
				}

				&.color4,&.info-news{
				background:#00aff8;
				}
				&.color5,&.info-technical-intern{
				background:#10b3d1;
				}

			}

			a{
			display: inline-block;
			font-size: 18px * 0.8;
			color: black;

				@media (max-width: 768px) {
				display: block;
				padding: 5px 0;
				}

			}

		}

	}/* .list end */

}/* .top .sec-news end */

.top .sec-cont{
margin-top: 100px;
margin-bottom: 170px;

	@include bp{
	margin-top: 80px;
	margin-bottom: 120px;
	}

	@media (max-width: 767px) {
	margin-top: 60px;
	margin-bottom: 80px;
	}

	figure{
	text-align: center;
	max-width: 1600px;
	margin:0 auto 65px;

		@include bp{
		margin:0 auto 45px;
		}


		img{
		width: 100%;
		}

	}

	.txt{
	font-size: 18px * 0.8;
	margin-bottom:100px;
	text-align: center;

		@include bp{
		margin-bottom: 50px;
		}

	}

	.flow{
	margin-bottom:65px;
	text-align: center;

		@include bp{
		margin-bottom: 40px;

			img{
			width: 100%;
			max-width: 767px;
			height: auto;
			}

		}

	}

	.bottom-list{
	max-width: 1080px;
	margin: auto;
	display: flex;
	justify-content: space-between;

		@media (max-width: 1100px) {
		max-width: 960px;
		}

		@include bp{
		padding: 0 20px;
		display: block;
		}

		li{
		width: 49%;
		border: 2px solid black;

			@media (max-width: 1024px) {
			width: 100%;
			margin-bottom: 20px;
			}

			@media (max-width: 767px) {
			margin-bottom: 10px;
			}


			a{
			color: black;
			display: block;
			position: relative;
			}

			.thumb{
			float: left;
			max-width: 284px;
			min-width: 284px;
			max-height: 213px;
			overflow: hidden;

				@media (max-width: 1100px) {
				max-width: 230px;
				min-width: 230px;
				max-height: 172px;
				}

				@media (max-width: 767px) {
				max-width:inherit;
				min-width: inherit;
				width: 40%;
				max-height: 96px;
				}

				@media (max-width: 360px) {
				max-height: 82px;
				}
				
				img{
				width: 100%;
				height: auto;
				vertical-align: top;

				}

			}

			.txt-area{
			float: right;
			width: calc(100% - 284px);
			box-sizing: border-box;
			padding:20px 15px;

				@media (max-width: 1100px) {
				width: calc(100% - 230px);
				}

				@media (max-width: 767px) {
				width: 60%;
				padding: 10px;
				}

				@media (max-width: 480px) {
				padding: 10px 10px 0;
				}

				h4{
				font-size: 25px * 0.8;
				font-family: $wf;
				margin-bottom: 10px;
				letter-spacing: 1px;

					@media (max-width: 1100px) {
					letter-spacing: 0;
					}

					@include bp{
					font-size: 22px;
					}

					@media (max-width: 767px) {
					font-size: 16px;
					}

					@media (max-width: 480px) {
					font-size: 12px;						
					}

				}
				
				.more{
				position: absolute;
				right: 20px;
				bottom: 20px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				font-family: $wf;

					@media (max-width: 480px) {
					text-indent: -9999px;
					bottom: 10px;
					right: 10px;
					}

					img{
					margin-left: 20px;

						@media (max-width: 480px) {
						width: 7px;
						height: auto;
						}

					}

				}

			}

		}

	}/* .bottom-list end */

}/* .top .sec-cont end */

.top .sec-cont3{

	.flow{

		@include bp{
		margin-bottom: 40px;

			img{
			width: 70%;
			max-width: 360px;
			height: auto;
			}

		}

	}


}/* .top .sec-cont3 end */
